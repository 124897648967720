<template>
  <div style="min-height: 436px" class="overflow-scroll">
    <h3
      class="
        text-blueGray-400
        uppercase
        font-bold
        text-md
        pt-5
        px-4
        text-center
        relative
      "
    >
      <i
        class="fas fa-arrow-left absolute left-7 top-6"
        @click="$emit('closeDamageReport')"
      ></i>
      {{ $t("damage report") }}
    </h3>
    <div class="flex-auto p-4">
      <nav class="">
        <div class="flex justify-center py-2">
          <span
            class="
              no-underline
              cursor-pointer
              text-center
              border-b-4
              uppercase
              tracking-wide
              font-bold
              text-xs
              py-1
              mr-8
            "
            @click="reportDamage = false"
            :class="{
              'text-blueGray-700 border-blueGray-700': !reportDamage,
              'text-blueGray-300 border-blueGray-300': reportDamage
            }"
          >
            {{ $t("reported") }}
          </span>
          <span
            class="
              no-underline
              cursor-pointer
              text-center
              border-b-4 border-transparent
              uppercase
              tracking-wide
              font-bold
              text-xs
              py-1
            "
            @click="reportDamage = true"
            :class="{
              'text-blueGray-700 border-blueGray-700': reportDamage,
              'text-blueGray-300 border-blueGray-300': !reportDamage
            }"
          >
            {{ $t("report damages") }}
          </span>
        </div>
      </nav>
      <div class="overflow-y-scroll h-96" v-if="!reportDamage">
        <template
          v-for="(
            {
              type,
              damage_type,
              damage_status,
              notes,
              read,
              children,
              inserted_at
            },
            i
          ) in bike_damages"
        >
          <div
            :key="`${damage_type}-${i}`"
            class="flex flex-wrap justify-center items-center mb-1 pt-5"
            v-if="read != true"
          >
            <div class="relative w-auto pl-4 flex-initial mr-3">
              <div
                class="
                  p-3
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  w-12
                  h-12
                  rounded-full
                "
                :class="{
                  'text-green-100 bg-green-500': damage_status == 'fixed',
                  'text-red-100 bg-red-500': damage_status == 'reported',
                  'text-yellow-100 bg-yellow-500': damage_status == 'nofix'
                }"
              >
                <i class="fas fa-tools"></i>
              </div>
            </div>
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 class="text-blueGray-400 uppercase text-xs">
                {{ type.name }}
              </h5>
              <span class="font-semibold text-lg text-blueGray-700">
                {{ notes }}
              </span>
              <h5 class="text-blueGray-400 uppercase text-xs">
                {{ inserted_at }}
              </h5>
              <div class="flex flex-wrap cursor-pointer">
                <div
                  class="w-1/2 flex items-center justify-center"
                  v-for="image in damageImages(children)"
                  :key="image.id"
                >
                  <img
                    class="p-2"
                    :src="image.path"
                    @click="setDialog(image.path)"
                  />
                </div>
                <el-dialog :visible.sync="dialogVisible" :fullscreen="true">
                  <div class="relative">
                    <i
                      class="
                        fas
                        fa-times-circle
                        text-3xl
                        absolute
                        -top-3
                        -right-3
                      "
                      @click="dialogVisible = false"
                    />
                    <img
                      class="mx-auto w-auto max-h-full p-8 bg-white"
                      :src="dialogVisibleImage"
                    />
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">
                      close
                    </el-button>
                  </span>
                </el-dialog>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="reportDamage">
        <h3
          class="
            text-blueGray-400
            uppercase
            font-bold
            text-md
            pt-5
            px-4
            relative
            mb-5
          "
        >
          {{ $t("damages type") }}
        </h3>
        <el-select
          style="width: 100%"
          v-model="$store.state.damages.damage.damage_type"
          placeholder="Select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <h3
          class="
            text-blueGray-400
            uppercase
            font-bold
            text-md
            pt-5
            px-4
            relative
            mb-5
          "
        >
          {{ $t("add new damages") }}
        </h3>
        <textarea
          v-model="$store.state.damages.damage.notes"
          class="border-gray-300 border-2 w-full rounded h-36 p-2"
        />
        <input
          class=""
          type="file"
          ref="uploadFile"
          @change="fileChange"
          accept="image/*"
          capture="camera"
          multiple
        />
        <div class="max-h-80 overflow-scroll flex flex-wrap">
          <div
            class="w-1/3 flex items-center object-contain h-30"
            v-for="(url, i) in imgUrls"
            :key="`urls-${i}`"
          >
            <img
              class="w-full h-full object-contain overflow-hidden"
              :src="url"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DamageReport",
  props: {},
  data() {
    return {
      imgUrls: [],
      // reportDamage: false,
      dialogVisible: false,
      dialogVisibleImage: ""
    };
  },
  computed: {
    ...mapState("shift", ["currentShift"]),
    ...mapState("tags", ["bike_damage_tags"]),
    ...mapState("typeDefinitions", ["bike_damage_definitions"]),
    reportDamage: {
      get() {
        return this.$store.state.reportDamage;
      },
      set(val) {
        this.$store.state.reportDamage = val;
      }
    },
    options() {
      return this.$store.state.typeDefinitions.bike_damage_definitions.map(
        ({ id: value, name: label }) => ({ value, label })
      );
    },
    damages() {
      return this.currentShift?.bike_damages
        ? this.currentShift?.bike_damages
        : [];
    },
    damageStatuses() {
      return this.bike_damage_definitions.map(item => ({
        label: item.name,
        value: item.id,
        show: true
      }));
    },
    bike_damages() {
      const statusMap = this.damageStatuses.reduce((accu, item) => {
        accu[item.value] = item.show;
        return accu;
      }, {});
      return this.currentShift.bike?.bike_damages
        ? this.currentShift?.bike?.bike_damages.filter(
            item => statusMap[item?.type?.id]
          )
        : [];
    }
  },
  methods: {
    setDialog(src) {
      this.dialogVisible = true;
      this.dialogVisibleImage = src;
    },
    damageImages(children) {
      return children
        .map(({ damage_images }) =>
          damage_images.length > 0 ? damage_images[0] : null
        )
        .filter(image => image != null);
    },
    fileChange() {
      this.$store.state.damages.file = this.$refs.uploadFile.files[0];
      this.imgUrls = Array.from(this.$refs.uploadFile.files).map(file =>
        URL.createObjectURL(file)
      );
    }
  },
  created() {
    this.$store.dispatch("tags/getAllTags");
    this.$store.dispatch("typeDefinitions/getAllBikeDamageDefinitions");
  }
};
</script>

<style>
.el-dialog__body {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100%;
}
.el-dialog.is-fullscreen {
  background: rgba(255, 255, 255, 0.7);
}
</style>
