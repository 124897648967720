<template>
  <div class="scanner-container bg-gray-300">
    <div
      v-show="showScanConfirmation"
      class="items-center min-w-full min-h-full flex flex-col py-32"
    >
      <i class="far fa-check-circle text-7xl text-green-700"></i>
      <h5 class="text-lg px-2">{{ result }}</h5>
    </div>
    <div
      v-show="isLoading"
      class="w-96 h-96 bg-gray-100 max-w-full"
      style="background: rgb(15 15 15)"
    />
    <div v-show="!isLoading">
      <video poster="data:image/gif,AAAA" ref="scanner" />
      <div class="overlay-element" />
      <div class="laser" />
    </div>
    <div
      v-show="!isLoading"
      class="absolute bottom-0 py-5 px-10 flex items-center justify-between w-screen"
    >
      <button
        @click="cancel"
        v-show="!isLoading"
        class="bg-transparent border-gray-50 text-gray-50 border-4 font-bold px-5 py-2 text-2xl"
      >
        {{ $t("Cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from "@zxing/library";
import MoodRating from "./MoodRating.vue";
export default {
  name: "stream-barcode-reader",
  props: {
    shift: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      result: null,
      isLoading: true,
      showScanConfirmation: false,
      triggered: false,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        "enumerateDevices" in navigator.mediaDevices
    };
  },
  mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception("Media Stream API is not supported");
    }
    this.$refs.scanner.oncanplay = () => {
      this.isLoading = false;
      this.$emit("loaded");
    };
    this.start();
  },
  beforeDestroy() {
    this.codeReader.reset();
  },
  methods: {
    cancel() {
      this.isLoading = true;
      setTimeout(() => this.$emit("cancel"), 50);
    },
    start() {
      this.codeReader.decodeFromVideoDevice(
        undefined,
        this.$refs.scanner,
        // (result, err) => {
        result => {
          if (result && result.text && !this.triggered) {
            this.triggered = true;
            this.showScanConfirmation = true;
            this.isLoading = true;
            this.result = result.text;

            this.$modal.show(
              MoodRating,
              {
                title: this.$t(`Confirm {shift}`, {
                  shift: this.$t(this.shift)
                }),
                text: this.result,
                buttons: [
                  {
                    title: this.$t("confirm"),
                    clickAble: rating => rating != null,
                    handler: rating => {
                      setTimeout(
                        () =>
                          this.$emit("accept", { bike: result.text, rating }),
                        500
                      );
                      // this.$modal.hide("dialog");
                      this.$modal.hideAll();
                    }
                  },
                  {
                    title: this.$t("rescan"),
                    clickAble: () => true,
                    handler: () => {
                      // this.$modal.hide("dialog");
                      this.$modal.hideAll();
                      this.showScanConfirmation = false;
                      this.isLoading = false;
                      this.result = null;
                      this.triggered = false;
                    }
                  },
                  {
                    title: this.$t("cancel"),
                    clickAble: () => true,
                    handler: () => {
                      // this.$modal.hide("dialog");
                      this.$modal.hideAll();
                      this.cancel();
                    }
                  }
                ]
              },
              {
                width: "95%",
                height: "375",
                clickToClose: false
              }
            );
          }
        }
      );
    }
  }
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
}
.scanner-container {
  position: relative;
}
.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    20% 100%,
    20% 20%,
    80% 20%,
    80% 80%,
    20% 80%,
    20% 100%,
    100% 100%,
    100% 0%
  );
}
.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
