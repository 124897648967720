<template>
  <div class="border-gray-500 w-screen bg-blueGray-800 text-gray-300 py-4">
    <div class="flex items-center justify-between mx-auto w-4/5">
      <router-link to="/"
        ><img
          alt="vault logo"
          width="70"
          src="@/assets/vault_transparent_white_logo.png"
        />
      </router-link>

      <div class="">
        <router-link v-if="!isLoggedIn" to="login">
          {{ $t("Login") }}
        </router-link>

        <p @click="$store.dispatch('accounts/logout')" v-if="isLoggedIn">
          {{ $store.state.accounts.user.name }}
          <i class="fas fa-sign-out-alt"></i>
        </p>

        <!-- <router-link
        class="mx-2 bg-gray-50 text-gray-500 px-3 py-1 rounded-sm"
        to="/"
        >{{ $t("Register") }}
      </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  computed: {
    isLoggedIn() {
      return this.$store.state.accounts?.user?.token ? true : false;
    }
  }
};
</script>
