import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    currentShift: {
      ...JSON.parse(localStorage.getItem("vault-rider/currentShift"))
    }
    // {
    //   id: null,
    //   name: "",
    //   email: "",
    //   token: "",
    //   ...JSON.parse(localStorage.getItem("vault-rider/currentShift"))
    // }
  };
};
const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters: {
    hasShift: state => Object.keys(state.currentShift).length > 0
  },
  mutations: {
    SHIFT_RESET(state) {
      localStorage.removeItem("vault-rider/currentShift");
      Object.assign(state, getDefaultState());
    },
    SHIFT_REQUEST(state) {
      localStorage.removeItem("vault-rider/currentShift");
      Object.assign(state, getDefaultState());
      state.loading = true;
    },
    SHIFT_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.currentShift = data;
      localStorage.setItem("vault-rider/currentShift", JSON.stringify(data));
    },
    SHIFT_FAILURE(state, error) {
      localStorage.removeItem("vault-rider/currentShift");
      Object.assign(state, getDefaultState());
      state.error = error;
    }
  },
  actions: {
    getCurrentShift: async ({ commit }) => {
      try {
        commit("SHIFT_REQUEST");
        const { data } = await axios.get(`/shifts/current`);
        commit("SHIFT_SUCCESS", data);
      } catch (err) {
        commit("SHIFT_FAILURE", err.response?.data);
      }
    },
    startShift: async ({ commit }, postData) => {
      try {
        commit("SHIFT_REQUEST");
        const { data } = await axios.post(`/shifts`, postData);
        commit("SHIFT_SUCCESS", data);
      } catch (err) {
        commit("SHIFT_FAILURE", err.response?.data);
        let message = err.response?.data?.error
          ? err.response?.data?.error
          : i18n.t("There was an error please try again");
        Notification.error({
          title: i18n.t("Error"),
          message
        });
      }
    },
    endShift: async ({ state, commit }, postData) => {
      const id = state.currentShift.id;
      try {
        commit("SHIFT_REQUEST");
        const { data } = await axios.put(`/shifts/${id}`, postData);
        commit("SHIFT_SUCCESS", data);
      } catch (err) {
        commit("SHIFT_FAILURE", err.response?.data);
        let message = err.response?.data?.error
          ? err.response?.data?.error
          : i18n.t("There was an error please try again");
        Notification.error({
          title: i18n.t("Error"),
          message
        });
      }
    }
  }
};
