import Vue from "vue";
import Vuex from "vuex";
import accounts from "./accounts";
import shift from "./shift";
import damages from "./damages";
import tags from "./tags";
import typeDefinitions from "./typeDefinitions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reportDamage: false
  },
  mutations: {},
  actions: {},
  modules: {
    accounts,
    damages,
    shift,
    tags,
    typeDefinitions
  }
});
