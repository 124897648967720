<template>
  <div id="app" class="relative">
    <Loading v-if="$store.state.shift.loading" />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Nav />
    <router-view />
    <footer class="block bottom-0 w-screen bg-blueGray-800">
      <div class="container mx-auto px-4">
        <hr class="border-b-1 border-blueGray-600" />
        <div
          class="flex flex-wrap items-center md:justify-between justify-center "
        >
          <div class="w-full md:w-4/12">
            <div
              class="text-sm text-blueGray-500 font-semibold text-center md:text-left py-3"
            >
              {{ $t("Copyright © 2021 vault") }}
            </div>
          </div>
          <!-- <div class="w-full md:w-8/12 px-4">
            <ul class="flex flex-wrap list-none md:justify-end justify-center">
              <li>
                <a
                  href="#"
                  class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
                >
                  Impressum
                </a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Loading from "@/components/Loading";
export default {
  name: "App",
  components: { Nav, Loading }
};
</script>
