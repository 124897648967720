<template>
  <div class="text-gray-500">
    <h1
      class="text-lg font-semibold text-center py-4 px-3 border border-gray-100 border-t-0 border-l-0 border-r-0"
    >
      {{ title }}
    </h1>
    <div class="px-3 border border-gray-100 border-t-0 border-l-0 border-r-0">
      <h2 class="text-7xl font-semibold text-center">
        {{ text }}
      </h2>
      <span class="text-center text-sm inline-block w-full -mt-3">
        {{ $t("bike number") }}
      </span>
    </div>
    <div class="pt-5 text-center text-sm">
      {{ $t("How do I feel right now?") }}
    </div>
    <div
      class="h-full flex flex-wrap justify-around items-center px-1 pb-5 py-2"
    >
      <div
        class="w-1/6 flex flex-col cursor-pointer border rounded py-1 relative text-center"
        :class="{
          'border-green-500': currentMood == value,
          'border-blueGray-100': currentMood != value
        }"
        v-for="{ value, emoji, label } in moods"
        :key="emoji"
        @click="currentMood = value"
      >
        <span class="text-4xl">{{ emoji }}</span>

        <i
          class="fas fa-check absolute -top-2 -right-2 bg-green-500 text-green-50 p-1 rounded-full text-xs"
          v-if="currentMood == value"
        />
        <span
          class="text-xs font-semibold text-center"
          :class="{
            'text-green-500': currentMood == value,
            'text-gray-500': currentMood != value
          }"
        >
          <span class="w-full text-center">
            {{ label }}
          </span>
        </span>
      </div>
    </div>
    <div class="bottom-0 left-0 absolute w-full" v-if="buttons">
      <button
        v-for="(button, index) in buttons"
        :class="{
          'w-1/3 border-t py-3': true,
          'border-l': index != 0,
          'pointer-events-none text-gray-300': !button.clickAble(currentMood)
        }"
        type="button"
        tabindex="0"
        :key="index"
        v-html="button.title"
        @click.stop="click(index, $event)"
      >
        {{ button.title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoodRating",
  props: {
    title: {
      type: String,
      require: false
    },
    text: {
      type: String,
      require: false
    },
    buttons: {
      type: Array,
      require: false
    }
  },
  data() {
    return {
      currentMood: null,
      moods: [
        { value: 1, emoji: "🤮", label: "worst" },
        { value: 2, emoji: "🤕", label: "poor" },
        { value: 3, emoji: "🤨", label: "average" },
        { value: 4, emoji: "🙃", label: "good" },
        { value: 5, emoji: "😆", label: "excellent" }
      ]
    };
  },
  methods: {
    getClass(index) {
      let res = `w-1/${this.buttons.length} border-t py-3`;
      if (index != 0) {
        res = `${res} border-l`;
      }
      return res;
    },
    click(buttonIndex) {
      const button = this.buttons[buttonIndex];
      const handler = button?.handler;
      if (typeof handler === "function") {
        // handler(buttonIndex, event, { source, rating: this.currentMood });
        handler(this.currentMood);
      }
    }
  }
};
</script>
