import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "tailwindcss/tailwind.css";
import i18n from "./i18n";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "typeface-montserrat";
import moment from "moment";
import VModal from "vue-js-modal";

import { Message, Select, Option, Dialog } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import ElementLocale from "element-ui/lib/locale";

Vue.config.productionTip = false;
Vue.prototype.$message = Message;
ElementLocale.i18n((key, value) => i18n.t(key, value));
Vue.use(VModal, { dialog: true });
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);

Vue.filter("momentago", checkintime => moment().diff(checkintime, "seconds"));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
