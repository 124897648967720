import axios from "../axios";

export default {
  namespaced: true,
  state: {
    bike_damage_tags: [],
    bike_status_labels: [],
    loading: false,
    error: null
  },
  mutations: {
    RESET_ERROR(state) {
      state.error = null;
    },
    GET_BIKE_DAMAGES_TAG_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_DAMAGES_TAG_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.bike_damage_tags = data;
    },
    GET_BIKE_DAMAGES_TAG_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike_damage_tags = [];
    },
    GET_BIKE_STATUS_LABELS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_STATUS_LABELS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.bike_status_labels = data;
    },
    GET_BIKE_STATUS_LABELS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike_status_labels = [];
    },
    ERROR_STATUS(state, error) {
      state.error = Object.entries(error).reduce((accu, [key, value]) => {
        accu = accu + `${key} ${value}`;
        return accu;
      }, "");
    }
  },
  actions: {
    getAllTags({ dispatch }) {
      dispatch("getAllBikeDamageTags");
      dispatch("getAllBikeStatusLabels");
    },
    getAllBikeDamageTags: async ({ commit }) => {
      try {
        commit("GET_BIKE_DAMAGES_TAG_REQUEST");
        const { data } = await axios.get(`/tags/bike-damages`);
        commit("GET_BIKE_DAMAGES_TAG_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_DAMAGES_TAG_FAILURE", err);
      }
    },
    getAllBikeStatusLabels: async ({ commit }) => {
      try {
        commit("GET_BIKE_STATUS_LABELS_REQUEST");
        const { data } = await axios.get(`/tags/bike-status`);
        commit("GET_BIKE_STATUS_LABELS_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_STATUS_LABELS_FAILURE", err);
      }
    }
  }
};
