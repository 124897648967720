<template>
  <div
    class="relative"
    :class="{ 'flex justify-center items-center content-center': startScanBtn }"
    style="height: calc(100vh - 105px)"
  >
    <div class="absolute h-screen bg-white w-screen" v-if="false">
      <mood-rating />
    </div>
    <div
      v-if="hasShift"
      class="bg-blueGray-800 top-0 h-1/3 absolute w-screen"
    />
    <div style="width: 80%" v-if="startScanBtn">
      <div
        v-if="hasShift && showDamageReport"
        class="
          relative
          flex flex-col
          min-w-0
          break-words
          bg-white
          rounded
          mb-6
          xl:mb-0
          shadow-xl
          mx-auto
        "
      >
        <DamageReport @closeDamageReport="closeDamageReport" />
      </div>
      <div
        style="min-height: 436px"
        v-if="hasShift && !showDamageReport"
        class="
          relative
          flex flex-col
          min-w-0
          break-words
          bg-white
          rounded
          mb-6
          xl:mb-0
          shadow-xl
          mx-auto
        "
      >
        <h3
          class="
            text-blueGray-400
            uppercase
            font-bold
            text-md
            pt-5
            px-4
            text-center
            flex
            items-center
            justify-around
          "
        >
          {{ $t("shift information") }}
          <button
            class="rounded bg-red-100 text-red-500 text-xs px-2 py-1"
            @click="addDamages"
          >
            <i class="fas fa-flag" />
            <!-- {{ $t("damages") }} -->
          </button>
        </h3>
        <div class="flex-auto p-4">
          <div
            v-for="{ label, value, icon } in showItems"
            :key="label"
            class="flex flex-wrap justify-center items-center mb-3"
          >
            <div class="relative w-auto pl-4 flex-initial mr-3">
              <div
                class="
                  p-3
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  w-12
                  h-12
                  rounded-full
                "
                :class="{
                  'text-green-100 bg-green-500': scanBtnName != 'done',
                  'text-red-100 bg-red-500': scanBtnName == 'done'
                }"
              >
                <i :class="icon"></i>
              </div>
            </div>
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
              <span class="font-semibold text-xl text-blueGray-700">
                {{ value }}
                <span v-if="!value">{{ $t("NA") }}</span>
              </span>
              <h5 class="text-blueGray-400 uppercase text-xs">
                {{ $t(label) }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="showScanBtn"
        class="
          bg-blueGray-500
          text-white
          active:bg-blueGray-600
          text-2xl
          font-bold
          uppercase
          px-8
          py-3
          rounded
          shadow
          hover:shadow-lg
          outline-none
          focus:outline-none
          mr-1
          mb-1
          w-full
          ease-linear
          transition-all
          duration-150
        "
        @click="onStartScan"
        :disabled="$store.state.damages.loading"
      >
        <i
          v-if="$store.state.damages.loading"
          class="fas fa-circle-notch fa-spin"
        />
        {{ $t(scanBtnName) }}
      </button>
    </div>

    <!-- <transition
      enter-active-class="transform transition ease-in-out duration-1000 sm:duration-1000"
      enter-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transform transition ease-in-out duration-1000 sm:duration-1000"
      leave-class="translate-y-0"
      leave-to-class="translate-y-full"
    >
      <DamageReport
        v-if="showDamageReport"
      />
    </transition> -->

    <transition
      enter-active-class="transform transition ease-in-out duration-1000 sm:duration-1000"
      enter-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transform transition ease-in-out duration-1000 sm:duration-1000"
      leave-class="translate-y-0"
      leave-to-class="translate-y-full"
    >
      <Scanner
        v-if="startScan"
        v-model="scanRes"
        @cancel="cancel"
        @accept="accept"
        :shift="scanBtnName"
      />
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Scanner from "@/components/Scanner.vue";
import DamageReport from "@/components/DamageReport.vue";
import { mapGetters, mapState } from "vuex";
import MoodRating from "@/components/MoodRating.vue";

export default {
  name: "Home",
  components: {
    Scanner,
    DamageReport,
    MoodRating
  },
  computed: {
    ...mapGetters("shift", ["hasShift"]),
    ...mapState("accounts", ["user"]),
    ...mapState("shift", ["currentShift"]),
    showScanBtn() {
      if (this.showDamageReport && !this.reportDamage) {
        return false;
      }
      return true;
    },
    reportDamage() {
      return this.$store.state.reportDamage;
    },
    code() {
      return this.currentShift?.bike_lock?.code;
    },
    scanBtnName() {
      if (this.showDamageReport) {
        return "save damages";
      } else if (this.hasShift) {
        return !this.currentShift.end ? "end shift" : "done";
      }
      return "start shift";
    },
    showItems() {
      let checkintime = this.currentShift.start
        ? moment(this.currentShift.start).format("HH:mm")
        : "";
      let checkindate = this.currentShift.start
        ? moment(this.currentShift.start).format("DD/MM/YYYY")
        : "";

      let baseItems = [
        { label: "name", value: this.user.name, icon: "far fa-user-circle" },
        {
          label: "bike",
          value: this.currentShift?.bike?.id,
          icon: "fas fa-bicycle"
        },
        { label: "unlock code", value: this.code, icon: "fas fa-key" },
        {
          label: "check-in date",
          value: checkindate,
          icon: "far fa-calendar"
        },
        {
          label: "check-in time",
          value: checkintime,
          icon: "far fa-clock"
        }
      ];
      if (this.currentShift.end) {
        let checkouttime = moment(this.currentShift.end).format("HH:mm");
        baseItems.push({
          label: "check-out time",
          value: checkouttime,
          icon: "fas fa-clock"
        });
      } else {
        baseItems.push({
          label: "total time",
          value: this.formatTime(this.timepassed),
          icon: "fas fa-stopwatch"
        });
      }
      return baseItems;
    }
  },
  watch: {
    hasShift(val) {
      if (val == true) {
        this.interval = setInterval(this.elapsed, 1000);
        this.bikeId = this.currentShift.bike.id;
      } else {
        clearInterval(this.interval);
      }
    }
  },
  data() {
    return {
      bikeId: null,
      scanRes: null,
      startScan: false,
      startScanBtn: true,
      // code: null,
      // code: "null",
      checkintime: "",
      timepassed: null,
      interval: null,
      showDamageReport: false
    };
  },
  methods: {
    closeDamageReport() {
      this.showDamageReport = false;
    },
    addDamages() {
      this.showDamageReport = true;
      // this.$modal.show(DamageReport, {
      //   text: "This text is passed as a property"
      // });
    },
    formatTime(seconds) {
      return [
        parseInt(seconds / 60 / 60),
        parseInt((seconds / 60) % 60),
        parseInt(seconds % 60)
      ]
        .join(":")
        .replace(/\b(\d)\b/g, "0$1");
    },
    cancel() {
      this.startScan = false;
      setTimeout(() => (this.startScanBtn = true), 1200);
    },
    elapsed() {
      this.timepassed = moment().diff(this.currentShift.start, "seconds");
    },
    accept({ bike, rating }) {
      if (this.bikeId == null) {
        // this.checkintime = moment();
        // this.code = Math.floor(1000 + Math.random() * 9000);
        // this.interval = setInterval(this.elapsed, 1000);
        this.bikeId = bike;
        this.cancel();
        this.$store.dispatch("shift/startShift", {
          shift: {
            bike_id: bike,
            battery_id: 1,
            mood_start: rating
          }
        });
      } else if (this.bikeId == bike) {
        // this.checkouttime = moment();
        // this.code = null;
        // clearInterval(this.interval);
        // let timestamp = this.checkouttime
        //   ? this.checkintime.format("DD/MM/YYYY HH:mm")
        //   : "";

        this.bikeId = null;
        this.$store.dispatch("shift/endShift", {
          shift: {
            bike_id: bike,
            battery_id: 1,
            mood_end: rating
          }
        });
        this.cancel();
        // this.$message({
        //   message: `${this.$t("you ended your shift at")} ${timestamp}`,
        //   type: "success"
        // });
      } else {
        this.$message({
          message: `${this.$t("please scan the correct bike")}`,
          type: "error"
        });
      }
    },
    onStartScan() {
      if (this.scanBtnName == "done") {
        this.$store.commit("shift/SHIFT_RESET");
      } else if (this.scanBtnName == "save damages") {
        if (this.$store.state?.damages?.damage?.damage_type) {
          this.$store.dispatch("damages/createDamage");
        } else {
          this.$message({
            message: `${this.$t("please select a damage type")}`,
            type: "error"
          });
        }
      } else {
        this.startScanBtn = false;
        this.startScan = true;
      }
    }
  },
  created() {
    this.$store.dispatch("shift/getCurrentShift");
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  animation: bounce-in 0.5s;
}
.fade-enter,
.fade-leave-to {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.vm--modal.vue-dialog {
  width: 92% !important;
  margin: 0 auto;
}
</style>
