import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";

const getDamagePayload = ({ damage, file }) => {
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append(`bike_damage[${"type_id"}]`, damage.damage_type);
    formData.append(`bike_damage[${"notes"}]`, damage.notes);
    formData.append(`bike_damage[${"bike_id"}]`, damage.bike_id);
    return formData;
  }
  return {
    bike_damage: {
      notes: damage.notes,
      bike_id: damage.bike_id,
      type_id: damage.damage_type
    }
  };
};

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    file: null,
    damage: {
      damage_type: "",
      notes: "",
      bike_id: ""
    }
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    DAMAGE_RESET(state) {
      Object.assign(state, getDefaultState());
    },
    DAMAGE_REQUEST(state) {
      state.loading = true;
    },
    DAMAGE_SUCCESS(state) {
      Object.assign(state, getDefaultState());
    },
    DAMAGE_FAILURE(state, error) {
      state.error = error;
      state.loading = false;
    }
  },
  actions: {
    createDamage: async ({ commit, state, rootState, dispatch }) => {
      try {
        commit("DAMAGE_REQUEST");
        state.damage.bike_id = rootState.shift.currentShift?.bike?.id;
        await axios.post(`/bike-damages`, getDamagePayload(state));
        commit("DAMAGE_SUCCESS");
        dispatch("shift/getCurrentShift", {}, { root: true });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Bike damage has been added!"),
          type: "success"
        });
      } catch (err) {
        commit("DAMAGE_FAILURE", err.response?.data);
        Notification.error({
          title: i18n.t("Error"),
          message: i18n.t("Something went wrong please try again.")
        });
      }
    }
  }
};
