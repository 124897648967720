var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative",class:{ 'flex justify-center items-center content-center': _vm.startScanBtn },staticStyle:{"height":"calc(100vh - 105px)"}},[(false)?_c('div',{staticClass:"absolute h-screen bg-white w-screen"},[_c('mood-rating')],1):_vm._e(),(_vm.hasShift)?_c('div',{staticClass:"bg-blueGray-800 top-0 h-1/3 absolute w-screen"}):_vm._e(),(_vm.startScanBtn)?_c('div',{staticStyle:{"width":"80%"}},[(_vm.hasShift && _vm.showDamageReport)?_c('div',{staticClass:"\n        relative\n        flex flex-col\n        min-w-0\n        break-words\n        bg-white\n        rounded\n        mb-6\n        xl:mb-0\n        shadow-xl\n        mx-auto\n      "},[_c('DamageReport',{on:{"closeDamageReport":_vm.closeDamageReport}})],1):_vm._e(),(_vm.hasShift && !_vm.showDamageReport)?_c('div',{staticClass:"\n        relative\n        flex flex-col\n        min-w-0\n        break-words\n        bg-white\n        rounded\n        mb-6\n        xl:mb-0\n        shadow-xl\n        mx-auto\n      ",staticStyle:{"min-height":"436px"}},[_c('h3',{staticClass:"\n          text-blueGray-400\n          uppercase\n          font-bold\n          text-md\n          pt-5\n          px-4\n          text-center\n          flex\n          items-center\n          justify-around\n        "},[_vm._v(" "+_vm._s(_vm.$t("shift information"))+" "),_c('button',{staticClass:"rounded bg-red-100 text-red-500 text-xs px-2 py-1",on:{"click":_vm.addDamages}},[_c('i',{staticClass:"fas fa-flag"})])]),_c('div',{staticClass:"flex-auto p-4"},_vm._l((_vm.showItems),function(ref){
var label = ref.label;
var value = ref.value;
var icon = ref.icon;
return _c('div',{key:label,staticClass:"flex flex-wrap justify-center items-center mb-3"},[_c('div',{staticClass:"relative w-auto pl-4 flex-initial mr-3"},[_c('div',{staticClass:"\n                p-3\n                text-center\n                inline-flex\n                items-center\n                justify-center\n                w-12\n                h-12\n                rounded-full\n              ",class:{
                'text-green-100 bg-green-500': _vm.scanBtnName != 'done',
                'text-red-100 bg-red-500': _vm.scanBtnName == 'done'
              }},[_c('i',{class:icon})])]),_c('div',{staticClass:"relative w-full pr-4 max-w-full flex-grow flex-1"},[_c('span',{staticClass:"font-semibold text-xl text-blueGray-700"},[_vm._v(" "+_vm._s(value)+" "),(!value)?_c('span',[_vm._v(_vm._s(_vm.$t("NA")))]):_vm._e()]),_c('h5',{staticClass:"text-blueGray-400 uppercase text-xs"},[_vm._v(" "+_vm._s(_vm.$t(label))+" ")])])])}),0)]):_vm._e(),(_vm.showScanBtn)?_c('button',{staticClass:"\n        bg-blueGray-500\n        text-white\n        active:bg-blueGray-600\n        text-2xl\n        font-bold\n        uppercase\n        px-8\n        py-3\n        rounded\n        shadow\n        hover:shadow-lg\n        outline-none\n        focus:outline-none\n        mr-1\n        mb-1\n        w-full\n        ease-linear\n        transition-all\n        duration-150\n      ",attrs:{"disabled":_vm.$store.state.damages.loading},on:{"click":_vm.onStartScan}},[(_vm.$store.state.damages.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.scanBtnName))+" ")]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"enter-active-class":"transform transition ease-in-out duration-1000 sm:duration-1000","enter-class":"translate-y-full","enter-to-class":"translate-y-0","leave-active-class":"transform transition ease-in-out duration-1000 sm:duration-1000","leave-class":"translate-y-0","leave-to-class":"translate-y-full"}},[(_vm.startScan)?_c('Scanner',{attrs:{"shift":_vm.scanBtnName},on:{"cancel":_vm.cancel,"accept":_vm.accept},model:{value:(_vm.scanRes),callback:function ($$v) {_vm.scanRes=$$v},expression:"scanRes"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }