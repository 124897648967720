import axios from "../axios";
import router from "../router";
import { Notification } from "element-ui";
import i18n from "../i18n";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    user: {
      id: null,
      name: "",
      email: "",
      token: "",
      ...JSON.parse(localStorage.getItem("vault-rider"))
    }
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters: {
    isAuthenticated: state => state.user.token
  },
  mutations: {
    LOGIN_REQUEST(state) {
      localStorage.removeItem("vault-rider");
      Object.assign(state, getDefaultState());
      state.loading = true;
    },
    LOGIN_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.user = data;
      localStorage.setItem("vault-rider", JSON.stringify(data));
    },
    LOGIN_FAILURE(state, error) {
      localStorage.removeItem("vault-rider");
      Object.assign(state, getDefaultState());
      state.error = error;
    },
    LOGOUT(state) {
      localStorage.removeItem("vault-rider");
      Object.assign(state, getDefaultState());
    }
    // SET_ITEM_LEVEL: (state, data) => (state.itemLevel = data)
  },
  actions: {
    login: async ({ commit }, postData) => {
      try {
        commit("LOGIN_REQUEST");
        const { data } = await axios.post(`/users/login`, postData);
        commit("LOGIN_SUCCESS", data);
        router.push({ name: "Home" });
      } catch (err) {
        commit("LOGIN_FAILURE", err);
        Notification.error({
          title: i18n.t("Error"),
          message: i18n.t("Please enter a valid username and password")
        });
      }
    },
    logout: ({ commit }) => {
      commit("LOGOUT");
      router.push({ name: "Login" });
    }
  }
};
