import axios from "axios";
import store from "./store";

export const getCookie = key => {
  var v = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

export const getJWT = () => {
  return getCookie("jwt");
};

const baseURL = process.env.VUE_APP_ROOT_API;

const instance = axios.create({
  baseURL
  // headers: {
  //   withCredentials: true
  // }
});

instance.interceptors.request.use(config => {
  let token = store.state.accounts.user?.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default instance;
